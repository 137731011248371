import React from "react";
import {MNGRLM_z_pcacd_usages_by_vehicleId} from "../endpoints/endpoints.js";
import axios from "axios";

import Header from "../header/header.js";
import ResultsVehicleCards from "./results-vehicle-cards.js";
import Footer from "../footer/footer.js";

import "../../css/results-vehicle.css";



class ResultsVehicle extends React.Component {

  constructor() {
    super();
    this.state = {
      onLoading: false,
      partsList: [],
      uniqueCategories: [],
      filteredPartsList: [],
      lubePartsList: [],
    };
    this.filterCategory = this.filterCategory.bind(this);
  }

componentDidMount() {

  // console.log(this.props.history.location.state.selectedYear)
  // console.log(this.props.history.location.state.selectedMake)
  // console.log(this.props.history.location.state.selectedModel)
  // console.log(this.props.history.location.state.selectedVersion)
  // console.log(this.props.history.location.state.selectedEngTransm)
  // console.log(this.props.history.location.state.selectedVehicleId)

    this._isMounted = true;
    this.loadData()

}

componentWillUnmount() {
    this._isMounted = false;
}  

loadData(){
  this.setState({onLoading: true })

    const query = `${MNGRLM_z_pcacd_usages_by_vehicleId}&pcacdid=${this.props.history.location.state.selectedVehicleId}&year=${this.props.history.location.state.selectedYear}`

    axios.get(query)
      .then(response => response.data)
      .then(data => {
      if (this._isMounted) {


        // Monta objeto com as categorias disponíveis
        var categories = data.map((cat) => cat.category)
        // Unique catagories
        var uniqueCategories = Array.from(new Set(categories))
        uniqueCategories.unshift("todas")

        var uniqueCategoriesUpper = uniqueCategories.map(element => {return element.toUpperCase()})

        //Sort by categoryId
        var dataSorted1 = data.sort((a,b) => a.categoryId - b.categoryId)

        this.setState({
          partsList: dataSorted1, 
          filteredPartsList: dataSorted1,
          uniqueCategories: uniqueCategoriesUpper,
          onLoading: false})

      }
    })
    .catch(err => {
      console.log(err); 
    }); 
}





filterCategory(category){

  var categoryLower = category.toLowerCase()

  var filteredPartsList = []

  if (categoryLower === "todas") {
    filteredPartsList = this.state.partsList
  } else {
    filteredPartsList = this.state.partsList.filter(obj => obj.category === categoryLower)
  }

  this.setState({filteredPartsList: filteredPartsList})
}


  render() {

    return (
      <div>

        <Header/>

        <div className="results-vehicle-wrap">

            <div className="results-vehicle-name-wrap">
              <div className="">
                  <span className="results-vehicle-name-main">{this.props.history.location.state.selectedMake}&nbsp;</span>
                  <span className="results-vehicle-name-main">{this.props.history.location.state.selectedModel}&nbsp;</span>
                  <span className="results-vehicle-name-main">{this.props.history.location.state.selectedYear}</span>
              </div>
              <div className="">
                  <span className="results-vehicle-name-secondary">{this.props.history.location.state.selectedVersion}&nbsp;&nbsp;</span>
                  <span className="results-vehicle-name-secondary">({this.props.history.location.state.selectedEngTransm})</span>
              </div>
            </div>


            <div className="results-make-wrap1">

              <div className="results-make-left-wrap">

                  <div className="results-make-filter-title">
                    Filtrar por categoria
                  </div>

                  <div className="results-make-filter-model-wrap">

                      <div className="results-make-filter-model">
                          {this.state.uniqueCategories.map((category, index) => {
                            return (
                              <div onClick={() => this.filterCategory(category)}>
                                {category}
                              </div>
                            )
                          })}
                      </div>

                </div>

              </div>

              <div className="results-make-right-wrap">

                <ResultsVehicleCards 
                resultsVehicleCards = {this.state.filteredPartsList}
                numberOfParts={this.state.filteredPartsList.length}
                uniqueCategories={this.state.uniqueCategories}
                />

              </div>

            </div>
    
        </div>

        <Footer/>

      </div>
    );
  }
}

export default ResultsVehicle;
