import React, { Fragment } from "react";

import "../../css/results-vehicle-cards.css";
import "../../css/results-category-cards.css";

import { ReactComponent as ArrowRight } from "../../img/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../img/arrow-left.svg";

import ResultsCategoryCard from "./results-category-card.js";

class ResultsCategoryCards extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      cardsPerPage: 12,
      prevNumberOfParts: this.props.numberOfParts
    };
  }


  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({currentPage: 1}) 
    }
  }


  render() {

    const numberOfPages = Math.ceil(this.props.resultsCategoryCards.length / this.state.cardsPerPage)
    const indexOfLastCard = this.state.currentPage * this.state.cardsPerPage;
    const IndexOfFirstCard = indexOfLastCard - this.state.cardsPerPage;
    const currentCards = this.props.resultsCategoryCards.slice(IndexOfFirstCard, indexOfLastCard);

    return (
    <div className="result-category-cards-wrap"> 

          {this.props.resultsCategoryCards.length === 0 ? (
            <div className="result-vehicle-cards-naoxistem">
              Não existem peças ACDelco para essa categoria
            </div>
          ):(

          <Fragment>

                <div className="navigation-categories">
                    <div className="navigation-total-parts">
                    {this.props.resultsCategoryCards.length} peças
                    </div>

                    <div className="navigation-arrow"
                    onClick={()=> {
                      this.state.currentPage === 1 ? 
                      this.setState({currentPage: 1}) 
                      :
                      this.setState({currentPage: this.state.currentPage - 1});}}>
                        <ArrowLeft style={{ height: "20px" }} alt="" />
                    </div>

                    <div className="navigation-page">
                      {this.state.currentPage}/{numberOfPages}
                    </div>

                    <div className="navigation-arrow"
                    onClick={()=> {
                      this.state.currentPage === numberOfPages ? 
                      this.setState({currentPage: numberOfPages}) 
                      :
                      this.setState({currentPage: this.state.currentPage + 1});}}
                      >
                        <ArrowRight style={{ height: "20px" }} alt="" />
                    </div>
                </div>


                <div className="result-category-cards">
                  {currentCards.map((vehicleCard, index) => {
                    return <ResultsCategoryCard key={index} {...vehicleCard}/>;
                  })}
                </div>

                {this.props.resultsCategoryCards.length > 12 ? (
                  <div className="navigation-categories">
                    <div className="navigation-total-parts">
                    {this.props.resultsCategoryCards.length} peças
                    </div>

                    <div className="navigation-arrow"
                    onClick={()=> {
                      this.state.currentPage === 1 ? 
                      this.setState({currentPage: 1}) 
                      :
                      this.setState({currentPage: this.state.currentPage - 1});}}
                      >
                        <ArrowLeft style={{ height: "20px" }} alt="" />
                    </div>

                    <div className="navigation-page">
                      {this.state.currentPage}/{numberOfPages}
                    </div>

                    <div className="navigation-arrow"
                    onClick={()=> {
                      this.state.currentPage === numberOfPages ? 
                      this.setState({currentPage: numberOfPages}) 
                      :
                      this.setState({currentPage: this.state.currentPage + 1});}}
                      >
                        <ArrowRight style={{ height: "20px" }} alt="" />
                    </div>
                  </div>
                ):(
                  null
                )}

          </Fragment>
          )
          }

    </div>
      
    );
  }
}

export default ResultsCategoryCards;
