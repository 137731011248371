import React , {Fragment} from "react";
import {MNGRLM_z_pcacd_usages_by_partn, MNGRLM_z_pcacd_part_availability} from "../endpoints/endpoints.js";
import axios from "axios";

import ModalUsages from "./modal-usages.js"
// import Loader from "./Loader.js";

import "../../css/results-vehicle-card.css";
import "../../css/results-category-card.css";

import FotoNaoDisp from "../../img/foto-nao-disponivel.jpg";


class ResultsCategoryCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onLoading: false,
      showModalUsages: false,
      selectedPartNumber: "",
      selectedPartName: "",
      selectedPartNameAddit: "",
      partUsagesList: [],
      available: false,
    };
    this.openModalUsages = this.openModalUsages.bind(this);
    this.closeModalUsages = this.closeModalUsages.bind(this);
    this.openPcb2c = this.openPcb2c.bind(this);
    
  }

componentDidMount() {
    this._isMounted = true;
    this.loadData()
}
componentWillUnmount() {
    this._isMounted = false;
} 


loadData(){
  this.setState({onLoading: true })

    const query = `${MNGRLM_z_pcacd_part_availability}&pn=${this.props.part_number}`

    axios.get(query)
      .then(response => response.data)
      .then(data => {
      if (this._isMounted) {

        if(data.length > 0) {
          this.setState({
            available: true,
            onLoading: false
          })
        } else {
          this.setState({
            available: false,
            onLoading: false
          })
        }

      }
    })
    .catch(err => {
      console.log(err); 
    }); 
}





closeModalUsages () {
    this.setState({ showModalUsages: false });
}




openModalUsages (partNumber, partName, partNameAddit) {

    this.setState({
      onLoading: true,
      selectedPartNumber: partNumber,
      selectedPartName: partName,
      selectedPartNameAddit: partNameAddit,
    })

    const query = `${MNGRLM_z_pcacd_usages_by_partn}&part_number=${partNumber}`



                function dynamicSort(property) {
                  var sortOrder = 1;
                  if(property[0] === "-") {
                      sortOrder = -1;
                      property = property.substr(1);
                  }
                  return function (a,b) {
                      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                      return result * sortOrder;
                  }
                }
              
                function dynamicSortMultiple() {
                  var props = arguments;
                  return function (obj1, obj2) {
                      var i = 0, result = 0, numberOfProperties = props.length;
                      while(result === 0 && i < numberOfProperties) {
                          result = dynamicSort(props[i])(obj1, obj2);
                          i++;
                      }
                      return result;
                  }
                }


    axios.get(query)
      .then(response => response.data)
      .then(data => {
      if (this._isMounted) {

        // Sort results by make, model, year
        var dataSorted = data.sort(dynamicSortMultiple("make", "model", "version", "fy"))

          this.setState({
            partUsagesList: dataSorted, 
            onLoading: false,
            showModalUsages: true
          })
      }
    })
    .catch(err => {
      console.log(err); 
    }); 
  }


  openPcb2c(partNumber, partNameUrl) {
    var url_pcb2c = `https://www.novopecachevrolet.com.br/peca/${partNumber}/${partNameUrl}`
    window.open(url_pcb2c)
  }



  render() {

    return (

        <div className="category-card-principal">


              <div className="category-card-foto">

                {this.props.photo_quantity > 0 ? (
                    <img 
                      src={`https://ik.imagekit.io/ab3nu4cvebb/parts-photos/${this.props.part_number}_1.jpg?tr=w-200`}
                      alt="foto da peca"
                      // onError={(e) => {
                      //   e.target.src = FotoNaoDisp
                      // }}
                    />
                ):(
                    <img 
                      src={FotoNaoDisp}
                      alt="foto da peca indisponível"
                    />
                )}
              </div>
              
              <div className="category-card-text">

                  <div className="category-card-text-pn-name-wrap">

                      <div className="category-card-text-pn">
                        {this.props.part_number}
                      </div>

                      <div className="category-card-text-name">
                        {this.props.part_name}
                      </div>

                      <div className="category-card-text-name-addit">
                        {this.props.part_name_addit}
                      </div>

                      <div className="category-card-ver-aplic" onClick={() => this.openModalUsages(this.props.part_number, this.props.part_name, this.props.part_name_addit)}>
                            {'>'} Ver aplicações
                      </div>
                  </div>

                  {this.state.onLoading ? (
                      <div className="onloading-wrap">
                          <img src="https://pcb2c-static-media-public.s3.amazonaws.com/loader.gif" alt="Loading..." />
                      </div>
                    ):( 
                      <Fragment>
                          {this.state.available ? (
                            <div className="category-card-botao-wrap">
                              <button className="category-card-botao-comprar-show" onClick={() => this.openPcb2c(this.props.part_number, this.props.part_name_url_pcb2c)} >
                                      COMPRAR
                              </button>
                            </div>
                          ):(
                          <div className="category-card-botao-wrap">
                            <button className="category-card-botao-comprar-show-notavailable" >
                                    INDISPONÍVEL
                            </button>
                          </div>
                          )}
                      </Fragment>
                    )
                  }

              </div>




            


              <Fragment>
                <ModalUsages 
                  selectedPartNumber={this.state.selectedPartNumber} 
                  selectedPartName={this.state.selectedPartName} 
                  selectedPartNameAddit={this.state.selectedPartNameAddit}
                  partUsagesList={this.state.partUsagesList}
                  show={this.state.showModalUsages}
                  handleClose={this.closeModalUsages}
                />
              </Fragment>
        </div>

    );
  }
}

export default ResultsCategoryCard;
