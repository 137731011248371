import React, { Fragment } from "react";

import "../../css/results-vehicle-cards.css";

import ResultsVehicleCard from "./results-vehicle-card.js";

class ResultsVehicleCards extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {

    return (
    <div className="result-vehicle-cards-wrap"> 

          {this.props.resultsVehicleCards.length === 0 ? (
            <div className="result-vehicle-cards-naoxistem">
              Não existem peças ACDelco para esse veículo
            </div>
          ):(

          <Fragment>

                  {this.props.resultsVehicleCards.map((vehicleCard, index) => {
                    return <ResultsVehicleCard key={index} {...vehicleCard}/>;
                  })}

          </Fragment>
          )
          }

    </div>
      
    );
  }
}

export default ResultsVehicleCards;
