import React from "react";
import { Link } from 'react-router-dom';

import makes2 from "../../json/makes2.json";

import "../../css/make-selector.css";

class MakeSelector extends React.Component {

  constructor() {
    super();
    this.state = {
      makes2: makes2,
      selectedMake: "",
      showButtonConsultar: false,
    };
    this.selectMake = this.selectMake.bind(this);
  }

  selectMake(e) {
    this.setState({ 
      selectedMake: e.target.value,
      showButtonConsultar: true
    })
  }


  render() {

    return (
      <div>

        <div className="make-selector-bkgd">
          <div className="make-selector-main-wrap">

              <div className="make-selector-title">
                CONSULTA POR MARCA
              </div>

              <div className="make-selector-text">
              Selecione abaixo uma das marcas e consulte as peças ACDelco disponíveis para os veículos desta marca:
              </div>

              <div className="make-selector-inputs-wrap">

                  <div className="make-selector-inputs-top-wrap">

                      <div className="makes2-wrap">
                        <div className="makes2-selectdiv">
                            <select value={this.state.selectedMake} onChange={this.selectMake}>
                              <option disabled={true} value="">Selecione a marca...</option>
                              {this.state.makes2.map((make, index1) => {
                                return <option key={index1} value={make.make}> {make.make} </option>
                              })}
                            </select>
                        </div>
                      </div>

                      <div className={this.state.showButtonConsultar ? "makes2-buttonConsultar-wrap" : "makes2-buttonConsultar-wrap-hide"}>
                          <Link to={{
                              pathname: `/marca/${this.state.selectedMake}`, 
                              state: {
                                selectedMake: this.state.selectedMake,
                          }}}> 
                            <button className="makes2-buttonConsultar">
                              CONSULTAR
                            </button>
                          </Link>
                      </div>


                  </div>

              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MakeSelector;
