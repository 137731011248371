import React from "react";

import { ReactComponent as LogoPdf } from "../../img/logo_pdf2.svg";
import {tabelas_pdf_urls} from "../../json/tabelas-acdelco-pdf.js"

import "../../css/table-pdf.css";
import "../../css/make-selector.css";

class TablePdf extends React.Component {

  constructor() {
    super();
    this.state = {
      pdfTables: tabelas_pdf_urls,
      selectedTable: "",
      showButtonConsultar: false,
    };
    this.selectTable = this.selectTable.bind(this);
  }



  selectTable(e) {

    console.log(e.target.value)

    var selectedPdfTable = tabelas_pdf_urls.filter(tbl => tbl.categoryName === e.target.value)

    console.log(selectedPdfTable[0].tabela)

    this.setState({ 
      selectedTable: selectedPdfTable[0].tabela,
      showButtonConsultar: true
    })
  }



  downloadPdfTable(){
    // window.open(tabelas_pdf_urls[12].tabela)
    window.open(this.state.selectedTable)
  }

  render() {

    return (

        <div className="table-pdf-bkgd">
          <div className="table-pdf-main-wrap">

              <div className="table-pdf-title">
                TABELAS EM FORMATO PDF
              </div>



              <div className="table-pdf-wrap1">
                  <div className="header-right-logo">
                    <LogoPdf style={{ height: "30px" }} alt="" />
                  </div>
                  <div className="table-pdf-text">
                    Selecione a tabela desejada para fazer o Download:
                  </div>
              </div>
              


              <div className="table-selector-inputs-top-wrap">

                  <div className="table-wrap">
                    <div className="table-selectdiv">
                        <select value={this.state.selectedTable} onChange={this.selectTable}>
                          <option disabled={true} value="">Selecione a tabela...</option>
                          {this.state.pdfTables.map((table, index1) => {
                            return <option key={index1} value={table.categoryName}> {table.categoryName} </option>
                          })}
                        </select>
                    </div>
                  </div>

                  <div className={this.state.showButtonConsultar ? "table-buttonConsultar-wrap" : "table-buttonConsultar-wrap-hide"}>
                        <button className="table-buttonConsultar" onClick={() => this.downloadPdfTable()}>
                          DOWNLOAD
                        </button>
                  </div>

              </div>


          </div>
        </div>
      
    );
  }
}

export default TablePdf;
