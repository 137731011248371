



// REALM KONG PROD
export const MNGRLM_z_pcacd_usages_by_vehicleId = "https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/api-search-b2c-prod-kucyd/service/b2cqueries/incoming_webhook/z_pcacd_usages_by_vehicleId?secret=mBshxs7UzWSs8Sr"
export const MNGRLM_z_pcacd_usages_by_partn = "https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/api-search-b2c-prod-kucyd/service/b2cqueries/incoming_webhook/z_pcacd_usages_by_partn?&secret=JQpMZZdxA4LiH7r"
export const MNGRLM_z_pcacd_parts_by_category = "https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/api-search-b2c-prod-kucyd/service/b2cqueries/incoming_webhook/z_pcacd_parts_by_category?&secret=4xwsEPyddJqjLMs"
export const MNGRLM_z_pcacd_usages_by_make = "https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/api-search-b2c-prod-kucyd/service/b2cqueries/incoming_webhook/z_pcacd_usages_by_make?&secret=137MZZdxA4LiH7r"
export const MNGRLM_z_pcacd_vehicles_by_make = "https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/api-search-b2c-prod-kucyd/service/b2cqueries/incoming_webhook/z_pcacd_vehicles_by_make?&secret=vF74zHCURdrWpJE"
export const MNGRLM_z_pcacd_part_availability = "https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/api-search-b2c-prod-kucyd/service/b2cqueries/incoming_webhook/z_pcacd_part_availability?&secret=gaDuX6dSZpyTSH7"
