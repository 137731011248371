import React from "react";
import {MNGRLM_z_pcacd_vehicles_by_make, MNGRLM_z_pcacd_usages_by_make} from "../endpoints/endpoints.js";
import axios from "axios";

import Header from "../header/header.js";
import ResultsMakeCards from "./results-make-cards.js";
import Footer from "../footer/footer.js";

import "../../css/results-make.css";

class ResultsMake extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      onLoading: false,
      vehicleList: [],
      filteredVehicleList: [],
      uniqueModels: [],
      partsList: [],
    };
    this.filterModel = this.filterModel.bind(this);
  }





componentDidMount() {
    this._isMounted = true;
    this.loadData()
}
componentWillUnmount() {
    this._isMounted = false;
} 




loadData(){
this.setState({onLoading: true })

const query1 = `${MNGRLM_z_pcacd_vehicles_by_make}&make=${this.props.history.location.state.selectedMake}`

      axios.get(query1)
      .then(response => response.data)
      .then(data1 => {
      if (this._isMounted) {

        this.setState({
          vehicleList: data1,
          filteredVehicleList: data1
      })

        //Monta lista de models unicos
        var uniqueModels1 = []
        Object.keys(data1).forEach(item => uniqueModels1.push(data1[item].model))
        var uniqueModels = [...new Set(uniqueModels1.flat())].sort()
        uniqueModels.unshift("Todos")
        this.setState({uniqueModels: uniqueModels});

            const query2 = `${MNGRLM_z_pcacd_usages_by_make}&make=${this.props.history.location.state.selectedMake}`

            axios.get(query2)
            .then(response => response.data)
            .then(data2 => {
            
            if (this._isMounted) {
      
              this.setState({partsList: data2, onLoading: false})
      
            }
            })
            .catch(err => {
              console.log(err); 
            }); 

      }
      })
      .catch(err => {
      console.log(err); 
      }); 
}





filterModel(model){
    
    var filteredVehicleList = []

    if (model === "Todos") {
      filteredVehicleList = this.state.vehicleList
    } else {
      filteredVehicleList = this.state.vehicleList.filter(obj => obj.model === model)
    }

    this.setState({filteredVehicleList: filteredVehicleList})
}



  render() {


    return (
      <div>

        <Header/>

        <div className="results-make-wrap">

            <div className="results-make-title">
                RESULTADOS DA CONSULTA POR MARCA: <span className="results-make-text">{this.props.history.location.state.selectedMake}</span>
            </div>

            <div className="results-make-wrap1">

              <div className="results-make-left-wrap">

                  <div className="results-make-filter-title">
                    Filtrar por modelo
                  </div>

                  <div className="results-make-filter-model-wrap">

                      <div className="results-make-filter-model">
                          {this.state.uniqueModels.map((model, index) => {
                            return (
                              <div onClick={() => this.filterModel(model)}>
                                {model}
                              </div>
                            )
                          })}
                      </div>

                </div>

              </div>

              <div className="results-make-right-wrap">

                <ResultsMakeCards 
                  resultsMakeVehicles = {this.state.filteredVehicleList}
                  resultsMakeUsages = {this.state.partsList}
                  numberOfVehicles={this.state.filteredVehicleList.length}
                />

              </div>

            </div>

        </div>

        <Footer/>

      </div>
    );
  }
}

export default ResultsMake;
