import React, {Fragment} from "react";
import DataTable , { defaultThemes } from 'react-data-table-component';

import Loader from "./Loader.js";
import { ReactComponent as CloseButton } from "../../img/closeButton.svg";
import "../../css/modal-usages.css";


const customStyles = {
  table: {
    style: {
      fontFamily: 'Overpass',
      maxHeight: '450px',
    },
  },

  headRow: {
    style: {
      backgroundColor: '#F1F2F1',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      fontWeight: 500,
      fontSize: '11px',
      color: '#707070',
      padding: '5px'
    },
  },
  rows: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: defaultThemes.default.divider.default,
    }
  },
  cells: {
    style: {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
  pagination: {
    style: {
      justifyContent: 'flex-start',
      fontFamily: 'Overpass',
      fontWeight: 300,
      fontSize: '10px',
  },
  },
};

const paginationOptions = { noRowsPerPage: true, rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' };


const columns = [

  {
    name: "Marca", 
    selector: row => row.make, 
    sortable: true, 
    style: {fontWeight: 100, fontSize: '11px', color: 'black'}, width: '150px'
  },
  {
    name: "Modelo", 
    selector: row => row.model, 
    sortable: true, 
    style: {fontWeight: 100, fontSize: '11px', color: 'black'}, width: '100px'
  },
  {
    name: "Anos", 
    selector: row => row.fy + "/" + row.ly, 
    sortable: true, 
    style: {fontWeight: 100, fontSize: '11px', color: 'black'}, width: '80px'
  },
  {
    name: "Versão", 
    selector: row => row.version, 
    sortable: true, 
    style: {fontWeight: 100, fontSize: '11px', color: 'black'}, width: '200px'
  },
  {
    name: "Motor/Transm", 
    selector: row => row.engtrans, 
    sortable: true, 
    style: {fontWeight: 100, fontSize: '11px', color: 'black'}, width: '250px'
  },
]





class ModalUsages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      handleClose: this.props.handleClose,
      onLoading: false,
    };
    this.closeDrawer = this.closeDrawer.bind(this);


  }

  closeDrawer() {
      this.props.handleClose()
  }






  render() {

    const showHideClassName = this.props.show ? "modalusages modalusages-show" : "modalusages modalusages-hide";

    return (

      <div className={showHideClassName}>
        <div className="modalusages-overlay" id="modalusages-overlay"></div>

        <div className="modalusages-main">

          <div className="modalusages-main-wrap">

            <div className="modalusages-header-wrap">

                  <div className="modalusages-header-part-wrap">
                    <div className="modalusages-header-part-number">
                      {this.props.selectedPartNumber}
                    </div>
                    <div className="modalusages-header-part-number">
                      &nbsp;-&nbsp;
                    </div>
                    <div className="modalusages-header-part-number">
                      {this.props.selectedPartName}
                    </div>
                  </div>

                  <div className="modalusages-close-wrap" onClick={() => this.closeDrawer()}>
                    <div className="modalusages-close-label">
                        Fechar
                    </div>
                    <CloseButton style={{ height: "20px" }} alt="" />
                  </div>

            </div>

            <div className="modalusages-top">

                <div className="modalusages-top-right">

                    <div className="modalusages-top-right-label">
                      
                    </div>
                    <div className="modalusages-top-right-text-color">
                    
                    </div>

                </div>

              </div>
              

              <div className="modalusages-bottom">


                    <div className="modalusages-aplicacao-table-wrap">
                      
                    </div>

              </div>

              <div className="modalusages-table-wrap">
                  <DataTable
                          noHeader={true}
                          columns={columns}
                          data={this.props.partUsagesList}
                          customStyles={customStyles}
                          pagination
                          paginationComponentOptions={paginationOptions}
                          paginationPerPage={10}
                          dense
                          noDataComponent={""}
                          highlightOnHover
                          defaultSortField="make"
                          defaultSortAsc={true}
                          persistTableHead
                        />
              </div>

          </div>
      </div> 
    </div> 
    );
  }

}

export default ModalUsages;
