import React, {Fragment} from "react";
import { Link } from 'react-router-dom';

import years from "../../json/years.json";
import makes from "../../json/makes.json";
import models from "../../json/models.json";
import versions from "../../json/versions.json";
import engTransm from "../../json/engines-transm.json";

import "../../css/vehicle-selector.css";



class VehicleSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      years: years,
      makes: [],
      models: [],
      versions: [],
      engTransm: [],
      selectedYear: "",
      selectedMake: "",
      selectedModel: "",
      selectedVersion: "",
      selectedEngTransm: "",
      selectedVehicleId: "",
      showMakes: false,
      showModels: false,
      showVersions: false,
      showEngTransm: false,
      redirect: "",
    };

    this.selectYear = this.selectYear.bind(this);
    this.selectMake = this.selectMake.bind(this);
    this.selectModel = this.selectModel.bind(this);
    this.selectVersion = this.selectVersion.bind(this);
    this.selectEngTransm = this.selectEngTransm.bind(this);
    
    
  }



  selectYear(e) {
    var yearMakes = makes.filter(marca => marca.year === e.target.value)

    this.setState({ 
      selectedYear: e.target.value,
      makes: yearMakes,
      selectedMake: "",
      models: [],
      versions: [],
      engTransm: [],
      showMakes: true,
      showModels: false,
      showVersions: false,
      showEngTransm: false,
      showButtonConsultar: false
    })

  }


  selectMake(e) {
    var yearMakeModels = models.filter(modelo => 
      modelo.year === this.state.selectedYear && 
      modelo.make === e.target.value
    )

    this.setState({ 
      selectedMake: e.target.value,
      models: yearMakeModels,
      versions: [],
      engTransm: [],
      showMakes: true,
      showModels: true,
      showVersions: false,
      showEngTransm: false,
      showButtonConsultar: false
    })
  }


  selectModel(e) {
    var yearMakeModelVersion = versions.filter(versao => 
      versao.year === this.state.selectedYear && 
      versao.make === this.state.selectedMake && 
      versao.model === e.target.value
    )
    yearMakeModelVersion = yearMakeModelVersion.sort((a, b) => a.version > b.version ? 1 : -1)

    this.setState({ 
      selectedModel: e.target.value,
      versions: yearMakeModelVersion,
      engTransm: [],
      showMakes: true,
      showModels: true,
      showVersions: true,
      showEngTransm: false,
      showButtonConsultar: false
    })
  }


  selectVersion(e) {
    var yearMakeModelVersionEngTr = engTransm.filter(engTransm => 
      engTransm.year === this.state.selectedYear && 
      engTransm.make === this.state.selectedMake && 
      engTransm.model === this.state.selectedModel && 
      engTransm.version === e.target.value
    )
    yearMakeModelVersionEngTr = yearMakeModelVersionEngTr.sort((a, b) => a.engtrans > b.engtrans ? 1 : -1)

    this.setState({ 
      selectedVersion: e.target.value,
      engTransm: yearMakeModelVersionEngTr,
      showMakes: true,
      showModels: true,
      showVersions: true,
      showEngTransm: true,
      showButtonConsultar: false
    })

  }




  selectEngTransm(e) {

    // Grava veículo selecionado no session storage
    sessionStorage.setItem("selectedYear", this.state.selectedYear)
    sessionStorage.setItem("selectedMake", this.state.selectedMake)
    sessionStorage.setItem("selectedModel", this.state.selectedModel)
    sessionStorage.setItem("selectedVersion", this.state.selectedVersion)
    sessionStorage.setItem("selectedEngTransm", e.target.selectedOptions[0].getAttribute('data-set'))
    sessionStorage.setItem("selectedVehicleId", e.target.value)


    this.setState({ 
      selectedEngTransm: e.target.selectedOptions[0].getAttribute('data-set'),
      selectedVehicleId: e.target.value,
      showButtonConsultar: true
     })
  }





  render() {

    return (
      <Fragment>

      <div className="vehicle-selector-bkgd">
          <div className="vehicle-selector-main-wrap">

              <div className="vehicle-selector-title">
                CONSULTA POR VEÍCULO
              </div>

              <div className="vehicle-selector-text">
              Selecione abaixo as opções de ano, marca, modelo, versão e motor do veículo que deseja pesquisar, e clique no botão Consultar para encontrar as peças ACDelco
aplicáveis ao mesmo.
              </div>


              <div className="vehicle-selector-inputs-wrap">

                <div className="vehicle-selector-inputs-top3-wrap">
                  <div className="years-wrap">
                    <div className="vehicle-selector-input-label">
                      ANO:
                    </div>
                    <div className="selectdiv">
                        <select value={this.state.selectedYear} onChange={this.selectYear}>
                          <option disabled={true} value="">Selecione o ano...</option>
                          {this.state.years.map((year, index1) => {
                            return <option key={index1} value={year.year}> {year.year} </option>
                          })}
                        </select>
                    </div>
                  </div>

                  <div className={this.state.showMakes ? "makes-wrap" : "makes-wrap-hide"}>
                    <div className="vehicle-selector-input-label">
                      MARCA:
                    </div>
                    <div className="selectdiv">
                        <select value={this.state.selectedMake} onChange={this.selectMake}>
                          <option value="">Selecione a marca...</option>
                          {this.state.makes.map((make, index2) => {
                            return <option  key={index2} value={make.make}> {make.make} </option>
                          })}
                        </select>
                    </div>
                  </div>

                  <div className={this.state.showModels ? "models-wrap" : "models-wrap-hide"}>
                    <div className="vehicle-selector-input-label">
                      MODELO:
                    </div>
                    <div className="selectdiv">
                        <select value={this.state.selectedModel} onChange={this.selectModel}>
                          <option value="">Selecione o modelo...</option>
                          {this.state.models.map((model, index3) => {
                            return <option  key={index3} value={model.model}> {model.model} </option>
                          })}
                        </select>
                    </div>
                  </div>
                </div>


                <div className="vehicle-selector-inputs-bottom3-wrap">
                    <div className={this.state.showVersions ? "versions-wrap" : "versions-wrap-hide"}>
                      <div className="vehicle-selector-input-label">
                        VERSÃO:
                      </div>
                      <div className="selectdiv">
                          <select value={this.state.selectedVersion} onChange={this.selectVersion}>
                            <option value="">Selecione a versão...</option>
                            {this.state.versions.map((version, index4) => {
                              return <option  key={index4} value={version.version}> {version.version} </option>
                            })}
                          </select>
                      </div>
                    </div>


                    <div className={this.state.showEngTransm ? "engtransm-wrap" : "engtransm-wrap-hide"}>
                      <div className="vehicle-selector-input-label">
                        MOTOR:
                      </div>
                      <div className="selectdiv">
                          <select value={this.state.selectedEngTransm} onChange={this.selectEngTransm}>
                            <option value="">Selecione o motor...</option>
                            {this.state.engTransm.map((engtrans, index5) => {
                              return <option  key={index5} value={engtrans.vehicleId} data-set={engtrans.engtrans}> {engtrans.engtrans} </option>
                            })}
                          </select>
                      </div>
                    </div>

                    <div className={this.state.showButtonConsultar ? "buttonConsultar-wrap" : "buttonConsultar-wrap-hide"}>

                              <Link to={{
                                  pathname: `/pecas-veiculo`, 
                                  state: {
                                    selectedYear: this.state.selectedYear,
                                    selectedMake: this.state.selectedMake,
                                    selectedModel: this.state.selectedModel,
                                    selectedVersion: this.state.selectedVersion,
                                    selectedEngTransm: this.state.selectedEngTransm,
                                    selectedVehicleId: this.state.selectedVehicleId,
                              }}}> 
                                <button className="buttonConsultar">
                                  CONSULTAR
                                </button>
                            </Link>
                    </div>


                </div>


              </div>
            </div>
        </div>

      </Fragment>

    );
  }
}

export default VehicleSelector;
