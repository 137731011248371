import React , {Fragment} from "react";
import {MNGRLM_z_pcacd_part_availability} from "../endpoints/endpoints.js";
import axios from "axios";
import { Redirect } from "react-router-dom";

import "../../css/results-vehicle-card.css";
import FotoNaoDisp from "../../img/foto-nao-disponivel.jpg";


class ResultsVehicleCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onLoading: false,
      imgSrc: "",
      available: false,
    };
    this.openPcb2c = this.openPcb2c.bind(this);
  }


  componentDidMount() {  
      this._isMounted = true;
      this.loadData()
  }
  
  componentWillUnmount() {
      this._isMounted = false;
  }  


  loadData(){
    this.setState({onLoading: true })
  
      const query = `${MNGRLM_z_pcacd_part_availability}&pn=${this.props.part_number}`
  
      axios.get(query)
        .then(response => response.data)
        .then(data => {
        if (this._isMounted) {
  
          if(data.length > 0) {
            this.setState({
              available: true,
              onLoading: false
            })
          } else {
            this.setState({
              available: false,
              onLoading: false
            })
          }
  
        }
      })
      .catch(err => {
        console.log(err); 
      }); 
  }


  openPcb2c(partNumber, partNameUrl) {
    var url_pcb2c = `https://www.novopecachevrolet.com.br/peca/${partNumber}/${partNameUrl}`
    window.open(url_pcb2c)
  }


  render() {

    // Redireciona para para pg do search quando uma das peças do history é clicada
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }


    return (

        <div className="vehicle-card-principal">

              <div className="vehicle-card-foto">

                {this.props.photo_quantity > 0 ? (
                    <img 
                      src={`https://ik.imagekit.io/ab3nu4cvebb/parts-photos/${this.props.part_number}_1.jpg?tr=w-200`}
                      alt="foto da peca"
                      // onError={(e) => {
                      //   e.target.src = FotoNaoDisp
                      // }}
                    />
                ):(
                    <img 
                      src={FotoNaoDisp}
                      alt="foto da peca indisponível"
                    />
                )}

              </div>

              <div className="vehicle-card-text">

                      <div className="vehicle-card-text-pn-name-wrap">
                        
                          <div className="vehicle-card-text-pn">
                            {this.props.part_number}
                          </div>

                          <div className="vehicle-card-text-name">
                            {this.props.part_name}
                          </div>

                          <div className="vehicle-card-text-name-addit">
                            {this.props.part_name_addit}&nbsp;{this.props.usage_note}
                          </div>
                      </div>

                      {this.state.onLoading ? (
                        <div className="onloading-wrap">
                            <img src="https://pcb2c-static-media-public.s3.amazonaws.com/loader.gif" alt="Loading..." />
                        </div>
                      ):( 
                        <Fragment>
                            {this.state.available ? (
                              <div className="vehicle-card-botao-wrap">
                                <button className="vehicle-card-botao-comprar-show" onClick={() => this.openPcb2c(this.props.part_number, this.props.part_name_url_pcb2c)} >
                                        COMPRAR
                                </button>
                              </div>
                            ):(
                            <div className="vehicle-card-botao-wrap">
                              <button className="vehicle-card-botao-comprar-show-notavailable" >
                                      INDISPONÍVEL
                              </button>
                            </div>
                            )}
                        </Fragment>
                        )
                      }
                      
              </div>
        </div>

    );
  }
}

export default ResultsVehicleCard;
