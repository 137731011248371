import React , {Fragment} from "react";

import "../../css/results-make-usages.css";


class ResultsMakeUsages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: "",
      categoryName: ""
    };
  }



  render() {

    var showUsages = "make-usage-principal-hide"
    if (this.props.showUsages) {
      showUsages = "make-usage-principal"
    }
    return (

        <div className={showUsages}>
                <div className="result-make-usages-wrap">

                    <div className="make-usage-text-category">
                      {this.props.category}
                    </div>

                    <div className="make-usage-text-fyly">
                      {this.props.fy}/{this.props.ly}
                    </div>

                    <div className="make-usage-text-pn">
                      {this.props.part_number}
                    </div>

          
                    <div className="make-usage-text-name">
                      {this.props.part_name}&nbsp;{this.props.part_name_addit} 
                    </div>

                    {this.props.usage_note ? (
                      <div className="make-usage-text-usage_note">
                        &nbsp;({this.props.usage_note})
                      </div>
                    ):(
                      null
                    )}

                </div>

        </div>

    );
  }
}

export default ResultsMakeUsages;
