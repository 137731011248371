
import React from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";

import ScrollToTop from "./scroll-to-top.js"
import Homepage from "./components/homepage/homepage.js";
import ResultsVehicle from "./components/results-vehicle/results-vehicle.js";
import ResultsCategory from "./components/results-category/results-category.js";
import ResultsMake from "./components/results-make/results-make.js";

import "./css/app.css";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {

    return (

        <BrowserRouter>
        <ScrollToTop/>
          <Switch>
            <Route exact path="/home" component={Homepage} />
            <Route exact path="/" component={Homepage} />

            <Route exact path="/pecas-veiculo" component={ResultsVehicle} />

            <Route exact path="/categoria/:categoria" component={ResultsCategory} />

            <Route exact path="/marca/:marca" component={ResultsMake} />

          
          </Switch>
        </BrowserRouter>

    );
  }
}

export default App;
