import React from "react";
import { withRouter } from "react-router-dom";

import { ReactComponent as LogoAcdelco } from "../../img/logo_acdelco.svg";

import "../../css/header.css";


class Header extends React.Component {

  constructor() {
    super();
    this.state = {
    };

    this.goHome = this.goHome.bind(this);
  }


  goHome(){
    this.props.history.push("/home")
  }

  
  render() {


    return (
      <div className="header-bkgd">

        <div className="header-wrap" >

            <div className="header-logo-pc" onClick={()=> this.goHome()}>
              <LogoAcdelco style={{ height: "35px" }} alt="" />
            </div>

            <div className="header-title-wrap">
                <div className="header-title1">
                  CATÁLOGO DE APLICAÇÕES
                </div>
            </div>
      
        </div>

      </div>
    );
  }
}

export default withRouter(Header);
