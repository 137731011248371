import React from "react";


import { ReactComponent as LogoAcdelco } from "../../img/logo_acdelco.svg";
import "../../css/footer.css";


class Footer extends React.Component {

  constructor() {
    super();
    this.state = {
    };
    this.handleLegalInfo = this.handleLegalInfo.bind(this);
  }


  //open legal information
  handleLegalInfo() {
    const url = "https://www.chevrolet.com.br/ajuda/politica-de-privacidade"
    window.open(url)
  }

  
  render() {


    return (
      <div className="footer-bkgd">
        <div className="footer-wrap" >

              <div className="footer-text-wrap" >
                <div className="footer-text1" >
                  Juntos Salvamos Vidas.
                </div>
                <div className="footer-text2" >
                  As informações contidas neste site são informações de propriedade da General Motors protegidas por marcas comerciais, direitos autorais e outras leis de propriedade intelectual. Ao usar este site, você reconhece e concorda que está expressamente proibido de reproduzir, distribuir, vender ou exibir qualquer um dos trabalhos contidos no site de qualquer outra forma sem a permissão expressa por escrito da General Motors.
                </div>
                <div className="footer-text3" >
                  Política de privacidade e proteção de dados pessoais da General Motors do Brasil:
                </div>
                <div className="footer-text4" onClick={() => this.handleLegalInfo()}>
                  www.chevrolet.com.br/ajuda/politica-de-privacidade
                </div>
                <div className="footer-text5">
                  24jun22
                </div>

              </div>

              <div className="footer-logo-pc">
                <LogoAcdelco style={{ height: "35px" }} alt="" />
              </div>
            
        </div>
      </div>
    );
  }
}

export default Footer;
