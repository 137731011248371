import React from "react";
import {MNGRLM_z_pcacd_usages_by_partn, MNGRLM_z_pcacd_parts_by_category} from "../endpoints/endpoints.js";
import axios from "axios";

import {tabelas_pdf_urls} from "../../json/tabelas-acdelco-pdf.js"

import Header from "../header/header.js";
import ResultsCategoryCards from "./results-category-cards.js";
import Footer from "../footer/footer.js";

import "../../css/results-category.css";

class ResultsCategory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      onLoading: false,
      partsList: [],
    };
  }

componentDidMount() {
    this._isMounted = true;
    this.loadData()
}
componentWillUnmount() {
    this._isMounted = false;
} 

loadData(){
  this.setState({onLoading: true })

    const query = `${MNGRLM_z_pcacd_parts_by_category}&categoryId=${this.props.history.location.state.selectedCategoryId}`

    axios.get(query)
      .then(response => response.data)
      .then(data => {
      if (this._isMounted) {


                function dynamicSort(property) {
                  var sortOrder = 1;
                  if(property[0] === "-") {
                      sortOrder = -1;
                      property = property.substr(1);
                  }
                  return function (a,b) {
                      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                      return result * sortOrder;
                  }
                }
              
                function dynamicSortMultiple() {
                  var props = arguments;
                  return function (obj1, obj2) {
                      var i = 0, result = 0, numberOfProperties = props.length;
                      while(result === 0 && i < numberOfProperties) {
                          result = dynamicSort(props[i])(obj1, obj2);
                          i++;
                      }
                      return result;
                  }
                }

        // Sort results by make, model, year
        var dataSorted = data.sort(dynamicSortMultiple("part_name", "part_number"))

        this.setState({partsList: dataSorted, onLoading: false})

      }
    })
    .catch(err => {
      console.log(err); 
    }); 
}




  render() {


    return (
      <div>

        <Header/>

        <div className="results-category-wrap">

            <div className="results-category-title">
              {this.props.history.location.state.selectedCategoryPageTitle}
            </div>
            <div className="results-category-text">
              {this.props.history.location.state.selectedCategoryPageText}
            </div>

            <ResultsCategoryCards 
                resultsCategoryCards = {this.state.partsList}
                numberOfParts={this.state.partsList.length}
            />

        </div>

        <Footer/>

      </div>
    );
  }
}

export default ResultsCategory;
