import React from "react";

import "../../css/results-make-usages-header.css";


class ResultsMakeUsagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }



  render() {

    var showUsages = "result-make-header-wrap-hide"
    if (this.props.showUsages) {
      showUsages = "result-make-header-wrap"
    }
    return (

        <div className={showUsages}>
                        <div className="result-make-header-category">
                          Categoria
                        </div>
                        <div className="result-make-header-years">
                          Anos
                        </div>
                        <div className="result-make-header-pn">
                          Nº da peça
                        </div>
                        <div className="result-make-header-partname">
                          Nome da peça / Notas
                        </div>
        </div>

    );
  }
}

export default ResultsMakeUsagesHeader;
