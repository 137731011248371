import React, { Component } from 'react';

class Loader extends Component {
	render() {
		return (
			<div className="loader-frete">
				<img src={require('../../img/loader.gif').default} alt="Loading..." />
			</div>
		)
	}
}

export default Loader;