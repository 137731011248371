import React from "react";
import { Link } from 'react-router-dom';

import categories from "../../json/categories.json";

import "../../css/category-selector.css";

class CategorySelector extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedCategory: ""
    };
  }




  render() {

    return (
      <div>

        <div className="category-selector-bkgd">
          <div className="category-selector-main-wrap">

              <div className="category-selector-title">
                CONSULTA POR CATEGORIA
              </div>

              <div className="category-selector-text">
              Selecione abaixo uma das categorias de peças ACDelco para consulta:
              </div>

              <div className="category-selector-cards-wrap">

                  {categories.map((categoria, index) => {
                      return (
                        <div key={categoria["categoria-id"]}>
                            <Link to={{
                              pathname: `/categoria/${categoria["categoria"]}`, 
                              state: {
                                selectedCategoryId: categoria["categoria-id"],
                                selectedCategory: categoria["categoria"],
                                selectedCategoryLabel: categoria["categoria-label"],
                                selectedCategoryPageTitle: categoria["category-page-title"],
                                selectedCategoryPageText: categoria["category-page-text"],
                              }}}> 

                              <div className="category-selector-card">
                                  <div className="category-selector-card-img">
                                    <img src={`https://ik.imagekit.io/ab3nu4cvebb/peca-acdelco/${categoria["categoria-img"]}?tr=w-150`} alt={`foto ${categoria["categoria.categoria"]} acdelco`} />
                                  </div>
                                  <div className="category-selector-card-label">
                                    {categoria["categoria-label"]}
                                  </div>
                              </div>
                            </Link>
                        </div>
                      )
                    })}

              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CategorySelector;
