export const tabelas_pdf_urls = 
[
    {
      categoryId: 1,
      categoryName: "Amortecedores",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_Amortecedores.pdf",
    },
    {
      categoryId: 2,
      categoryName: "Bombas de Água",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_BombasDeAgua.pdf",
    },
    {
      categoryId: 3,
      categoryName: "Bombas de Combustivel",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_BombasDeCombustivel.pdf",
    },
    {
      categoryId: 4,
      categoryName: "Cabos de Vela",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_CabosDeVela.pdf",
    },
    {
      categoryId: 5,
      categoryName: "Correias",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_Correias.pdf",
    },
    {
      categoryId: 6,
      categoryName: "Discos de Freio",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_DiscosDeFreio.pdf",
    },
    {
      categoryId: 7,
      categoryName: "Filtros",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_Filtros.pdf",
    },
    {
      categoryId: 8,
      categoryName: "Palhetas",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_Palhetas.pdf",
    },
    {
      categoryId: 9,
      categoryName: "Pastilhas de Freio",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_PastilhasDeFreio.pdf",
    },
    {
      categoryId: 10,
      categoryName: "Lubrificantes e Quimicos",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_Lubrificantes_Quimicos.pdf",
    },
    {
      categoryId: 11,
      categoryName: "Tensionadores",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_Tensionadores.pdf",
    },
    {
      categoryId: 12,
      categoryName: "Velas de Ignicao",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_VelasDeIgnicao.pdf",
    },
    {
      categoryId: 13,
      categoryName: "Baterias",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco_Baterias.pdf",
    },
    {
      categoryId: 14,
      categoryName: "Tabela completa",
      tabela: "https://pcb2c-static-media-public.s3.amazonaws.com/tabelasACDelco/Tabela_ACDelco.pdf",
    }
  ]
