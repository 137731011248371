import React from "react";

import Header from "../header/header.js";
import VehicleSelector from "../vehicle-selector/vehicle-selector.js";
import CategorySelector from "../category-selector/category-selector.js";
import MakeSelector from "../make-selector/make-selector.js";
import TablePdf from "../table-pdf/table-pdf.js";
import Footer from "../footer/footer.js";

import "../../css/homepage.css";

class Homepage extends React.Component {

  constructor() {
    super();
    this.state = {
    };

  }




  render() {


    return (
      <div>

        <Header/>

        <div className="main-body-fixed-header">

          <VehicleSelector/>
          
          <MakeSelector/>

          <TablePdf/>

          <div className="homepage-divider"/>
          
          <CategorySelector/>

          <div className="homepage-divider"/>

          <div className="banner-sobre-wrap">

                <div className="banner-sobre-img">
                    <img src={`https://ik.imagekit.io/ab3nu4cvebb/peca-acdelco/acdelco-sobre.png?tr=w-600`} alt="sobre a ACDelco" />
                </div>

                <div className="banner-sobre-text-wrap">

                    <div className="banner-sobre-text-title">
                      SOBRE A ACDELCO
                    </div>
                    <div className="banner-sobre-text-main">
                      A ACDelco é uma marca mundial presente em mais de 60 países com fornecimento de peças automotivas e soluções para veículos de todas as marcas.
                    </div>

                </div>

          </div>

        </div>

        <Footer/>
        
      </div>
    );
  }
}

export default Homepage;
