import React from "react";

import ResultsMakeUsages from "./results-make-usages.js";
import ResultsMakeUsagesHeader from "./results-make-usages-header.js";

import "../../css/results-make-card.css";

class ResultsVehicleCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: "",
      showUsages: false
    };
    this.showUsages = this.showUsages.bind(this);
  }

  showUsages(){
    this.setState({showUsages: !this.state.showUsages})
  }

  render() {

    return (
      <div>
        <div className="make-card-principal" onClick={() => this.showUsages()}>

              <div className="make-card-text-model" >
                {'>'} {this.props.model}&nbsp;-&nbsp;
              </div>

              <div className="make-card-text-version">
                {this.props.version}&nbsp;
              </div>

              <div className="make-card-text-engtrans">
                {`[${this.props.engtrans}]`}
              </div>

        </div>

        <div className="result-make-cards">
                  <ResultsMakeUsagesHeader showUsages={this.state.showUsages}/>
                  {this.props.usages.map((usage, index) => {
                    return <ResultsMakeUsages key={index} {...usage} showUsages={this.state.showUsages}/>;
                  })}
        </div>

      </div>

    );
  }
}

export default ResultsVehicleCard;
